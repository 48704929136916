import React from 'react';
import NavigationFooter from '../home/footer/NavigationFooter.js';
import ButtonGoHome from '../common/ButtonGoHome.js';

export default function Security(){

    return(<div className="security">
            <div className='security-container'>
                <div className='security-page'>
                <ButtonGoHome/>
                <div><NavigationFooter/></div> 
                <br/><br/><br/>
                <h2>SECURITY</h2>

                <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. Our site uses the latest Secure Socket Layers (SSL) security.
                </p>

                <h2>Controlling your personal information</h2>
                <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required to do so by law.
                </p>

                <h2>Disclosures </h2>
                <p>to whom do we regularly disclose personal information?
                We may disclose information that we hold to related companies within our corporate group for marketing purposes.
                </p>

                <h2>Sharing information with third parties </h2>
                <p>www.alisajewellery.com.au does not share, sell or trade your personal information gathered online, such as email addresses or postal addresses. Personal information collected online will only be disclosed within our corporate group for marketing purposes.
                </p>
                <h2>Information deletion </h2>
                <p>Any information that we no longer require is destroyed securely. An exception to this may be retention of the information for data analysis. However, if this occurs then the information will be retained in a form that does not allow you to be identified from that information.
                </p>
                <h2>Security</h2>
                <p>Any personal information that you submit will be protected by our secure server. We might, on occasions, use this information to notify you of any important changes to our site or any special promotions that may be of interest to you. However, www.alisajewellery.com will not sell or trade your personal information to any third party organisations or individuals without your consent other than third party suppliers in the delivery of services you have agreed to accept from www.alisajewellery.com or if we are requested to do so by a law enforcement agency in relation to a suspected breach of any law.

                By using the Alisa Jewellery website, you are consenting to the collection of information www.alisajewellery.com if any changes to these policies occur, we will notify our customers by updating this section of our website.
                </p>

                <h2>Privacy rights</h2>

                <p>If you believe that our organisation has breached your privacy rights in any way, or you would like to discuss any issues about our privacy policy please email us to customerservice@alisajewellery.com. We will try to satisfy any questions that you have and correct any errors on our part.
                </p>


                </div>
            </div>
            </div>)
    
}