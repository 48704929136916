import React from 'react';
import NavigationFooter from '../home/footer/NavigationFooter.js';
import ButtonGoHome from '../common/ButtonGoHome.js';

export default function ShippingAndReturns(){

    
    return(<div className="shippings-and-returns">
            <div className="shippings-and-returns-container">
                <div className="shippings-and-returns-page">
                    <ButtonGoHome/>
                    <div><NavigationFooter/></div> 

                    <br/><br/><br/>
                    <h2>Shipping and returns</h2>

                    <h2>Shipping</h2>
                    <p>
                    We can ship to virtually any address in the world. We send all our packages using tracking numbers and signatures on delivery.When you place an order, we will estimate shipping and delivery dates for you based on the availability of your items.
                    Please also note that the shipping rates for many items we sell are “Amount of Total order” based. Therefore several items can be purchased at the same time and sent together as one single order.
                    We recommend you have your parcel sent to a suitable daytime delivery address to ensure that someone will be present to sign for your parcel between the delivery hours: 09:00 and 17:00 Monday to Friday. Your signature will be requested upon delivery. Deliveries cannot be made to PO Boxes.

                    Please allow 1 - 5 working days for your order to be processed and a further 2 - 15 working days for delivery.

                    Once your order is sent you will receive an email indicating that your order has been shipped, it will outline estimated delivery time and online tracking service information. Please allow up to 2 to 15 working days to reach you depending on your location. Orders placed on a weekend, will be processed next business day.If your pieces of jewellery do not arrive within this timeframe please email us and we will follow this up for you.

                    It is the customer’s responsibility to enter the correct shipping address details at the time of ordering. Should you enter the wrong address and the goods are not returned to us within 20 working days of shipping, we will not refund your payment or replace the goods for you.

                    Any delivery date specified by us is an estimate only. We will not be held liable for any loss or damage suffered by you in case of any reasonable or unavoidable delay in delivery or while the goods are in transit. All orders are subject to availability and we are unable to redirect orders that have already been dispatched.

                    Any questions or doubts please email us to: Customerservice@alisajewellery.com
                    </p>


                    <h2>Returns Policy</h2>

                    <p>
                    We will happily refund or exchange the item you have bought if it is faulty or not as ordered. It may happen that we can mix an order; however we do verify and control the quality of our pieces of jewellery before they are shipped. We are unable to refund any purchased item if you changed your mind or you decided you no longer wanted the item after you completed the purchase process, but we can gladly exchange any item you bought from our website if you decide you like more another  item after you completed a given purchase process.

                    To return products or exchanges you must:

                    1- Contact us informing the situation describing the state of the item and the faults or your wish to exchange it for another item

                    2- Return the item within 14 days of purchase

                    3-The item is in its original condition with its original presentation bag. Please ensure the item is packed securely on return as we are unable to refund if the external packaging or sealing is broken when arrives to us

                    4-Place a copy of the invoice/sale receipt with the returning item so we can match it with your claim

                    In case of faulty items or not as ordered we will refund the money to your nominated bank account or credit card. The refund will be processed as soon as the items have been received and inspected. We strongly suggest you use secure packaging with tracking number such as Express Post or Registered Post. We are not responsible for customer returns or for any parcel sent by a customer that is lost in transit. You must choose carefully your postage type and shipping company. Please allow 20 days after you ship the items for us to receive them. After we receive the item(s) we will check immediately their state/condition and also if they match your claim. If there is a match we will proceed to refund your money within 48 hours of receipt.  You will be refunded the full value of the item(s) purchased however shipping charges are NOT refundable.

                    In case of exchanges please enclose, address, contact person and phone number for us to send the exchange product in a secure manner. The exchange will be processed as soon as the items have been received and inspected. We strongly suggest you use secure packaging with tracking number such as Express Post or Registered Post. We are not responsible for customer returns or for any parcel sent by a customer that is lost in transit. You must choose carefully your postage type and shipping company. Please allow 20 days after you ship the items for us to receive them. After we receive the items we will check immediately their state/condition and also if they match your claim. If there is a match we will proceed to send the exchange items within 48 hours of receipt.  You may incur in extra shipping charges ONLY for the items exchanged sent to your nominated address.
                    </p>
                    <p>
                    Any questions or doubts please email us to: Customerservice@alisajewellery.com
                    </p>
                </div>
            </div>
        </div>)
    
}