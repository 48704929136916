import React from 'react';
import HomeIndex from './website/home/HomeIndex.js';

export default function App(){
    return (
        <div className="App-Section">
           {/* Hello React!! it works!! super RED!! */}
           <HomeIndex/>
        </div>
    )
}